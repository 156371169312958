import { useState, useEffect } from 'react';
import './OICCLaTeX.css'
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-latex';
import InputTag from '../components/InputTag';

const OICCLaTeX = () => {

    const journalsInfo = {
        'jtap':{title:'Journal of Theoretical and Applied Physics (JTAP)', abbreviation: 'JTAP', ISSN:'2251-7227', referenceStyle:''},
        'gcr':{title:'Geoconservation Research (GCR)', abbreviation: 'GCR', ISSN:'', referenceStyle:''},
        'ijrowa':{title:'International Journal of Recycling of Organic Waste in Agriculture (IJROWA)', abbreviation: 'IJROWA', ISSN:'2195-3228', referenceStyle:'-Chicago'},
        'jrs':{title:'Journal of Rangeland Science (JRS)', abbreviation: 'JRS', ISSN:'2008-9996', referenceStyle:'-Chicago-JRS'},
        'ap':{title:'Anthropogenic Pollution (AP)', abbreviation: 'AP', ISSN:'2588-4646', referenceStyle:'-Chicago'},
        'mjee':{title:'Majlesi Journal of Electrical Engineering (MJEE)', abbreviation: 'MJEE', ISSN:'2345-377X', referenceStyle:''},
        'ijc':{title:'Iranian Journal of Catalysis (IJC)', abbreviation: 'IJC', ISSN:'2252-0236', referenceStyle:'-IJC'},
        'ijic':{title:'International Journal of Industrial Chemistry (IJIC)', abbreviation: 'IJIC', ISSN:'2251-7227', referenceStyle:''},
        'ijnd':{title:'International Journal of Nano Dimension (IJND)', abbreviation: 'IJND', ISSN:'2008-8868', referenceStyle:''},
        'ijes':{title:'Iranian Journal of Earth Sciences (IJES)', abbreviation: 'IJES', ISSN:'2008-8779', referenceStyle:''}
    }

    useEffect(() => {
        document.title = 'OICC LaTeX Generator | Science Proof';
    }, []);

    const [tagData, setTagData] = useState([]);
    const [articleType, setArticleType] = useState('Original Research');
    const [articleTypeOther, setArticleTypeOther] = useState('');
    const [volume, setVolume] = useState('');
    const [issue, setIssue] = useState('');
    const [articleNumber, setArticleNumber] = useState('');
    const [doi, setDoi] = useState('');
    const [title, setTitle] = useState('');
    const [headerTitle, setHeaderTitle] = useState('');
    const [latexCode, setLatexCode] = useState('');
    const [journal, setJournal] = useState('jtap');
    const [receivedDay, setReceivedDay] = useState(1);
    const [receivedMonth, setReceivedMonth] = useState(0);
    const [receivedYear, setReceivedYear] = useState(2025);
    const [revisedDay, setRevisedDay] = useState(1);
    const [revisedMonth, setRevisedMonth] = useState(0);
    const [revisedYear, setRevisedYear] = useState(2025);
    const [acceptedDay, setAcceptedDay] = useState(1);
    const [acceptedMonth, setAcceptedMonth] = useState(0);
    const [acceptedYear, setAcceptedYear] = useState(2025);
    const [publishedDay, setPublishedDay] = useState(1);
    const [publishedMonth, setPublishedMonth] = useState(0);
    const [publishedYear, setPublishedYear] = useState(2025);
    const [authors, setAuthors] = useState([{firstName: '', lastName: '', affiliations: [0], hasOrcid: false, orcid: '', emails: []}]);
    const [affiliations, setAffiliations] = useState(['']);
    const [style, setStyle] = useState('OICC');

    const resetAll = () => {
        setTagData([]);
        setArticleType('Original Research');
        setArticleTypeOther('');
        setVolume('');
        setIssue('');
        setArticleNumber('');
        setDoi('');
        setTitle('');
        setHeaderTitle('');
        setLatexCode('');
        setJournal('jtap');
        setReceivedDay(1);
        setReceivedMonth(0);
        setReceivedYear(2025);
        setRevisedDay(1);
        setRevisedMonth(0);
        setRevisedYear(2025);
        setAcceptedDay(1);
        setAcceptedMonth(0);
        setAcceptedYear(2025);
        setPublishedDay(1);
        setPublishedMonth(0);
        setPublishedYear(2025);
        setAuthors([{firstName: '', lastName: '', affiliations: [0], hasOrcid: false, orcid: '', emails: []}]);
        setAffiliations(['']);
        setStyle('OICC')
    }  

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const updateAuthor = (id, attributeName, newValue) => {
        setAuthors(authors.map((item, index) => index === id ? {...item, [attributeName]:newValue} : item));
    };

    const updateAuthorEmail = (authorID, emailID, newValue) => {
        var newEmails = [...authors[authorID].emails]
        newEmails[emailID] = newValue
        setAuthors(authors.map((item, index) => index === authorID ? {...item, emails:newEmails} : item));
    };

    const handleAuthorAffiliations = (event, authorID) => {
        var selectedValues = Array.from(event.target.selectedOptions, (option) => parseInt(option.value));
        if (selectedValues.length > 0) {
            updateAuthorAffiliations(authorID, selectedValues)
        }
        else {
            updateAuthorAffiliations(authorID, [0])
        }
    };

    const updateAuthorAffiliations = (authorID, newValue) => {
        setAuthors(authors.map((item, index) => index === authorID ? {...item, affiliations:newValue} : item));
    };

    const removeAuthorEmail = (authorID, emailID) => {
        var newEmails = [...authors[authorID].emails]
        newEmails.splice(emailID, 1)
        setAuthors(authors.map((item, index) => index === authorID ? {...item, emails:newEmails} : item));
    };

    const addAuthorEmail = (authorID) => {
        var newEmails = [...authors[authorID].emails, '']
        setAuthors(authors.map((item, index) => index === authorID ? {...item, emails:newEmails} : item));
    };
    
    const addAuthor = () => {
        setAuthors([...authors, {firstName: '', lastName: '', affiliations: [0], hasOrcid: false, orcid: '', emails: []}]);
    };
    
    const removeAuthor = (id) => {
        if (authors.length <= 1) {
            return
        }
        setAuthors(authors.filter((item, index) => index !== id));
    };

    const updateAffiliation = (index, newValue) => {
        setAffiliations((prevAffiliation) => {
            const newAffiliation = [...prevAffiliation];
            newAffiliation[index] = newValue;
            return newAffiliation;
          });
    };

    const removeAffiliation = (id) => {
        if (affiliations.length <= 1) {
            return
        }
        authors.forEach((author, index) => {
            var newAffiliations = []
            author.affiliations.forEach((aff, i) => {
                if (aff < id) {
                    newAffiliations = newAffiliations.concat(aff)
                }
                else if (aff > id) {
                    newAffiliations = newAffiliations.concat(aff - 1)
                }
            })
            if (newAffiliations.length === 0) {
                newAffiliations = [0]
            }
            updateAuthorAffiliations(index, newAffiliations)
        })
        setAffiliations(affiliations.filter((item, index) => index !== id));
    };

    const getAffiliationsOrder = () => {
        var affOrder = []
        authors.forEach((author) => {
            author.affiliations.forEach((aff) => {
                if (!affOrder.includes(aff)) {
                    affOrder = affOrder.concat(aff)
                }
            })
        })
        return affOrder
    }

    const authorsLaTeX = (affiliationsOrder) => {
        var all = []
        authors.forEach((author) => {
            var complete = author.firstName.trim() + ' ' + author.lastName.trim()
            var aff = []
            var close = false
            if (affiliationsOrder.length > 1) {
                author.affiliations.forEach((authorAff) => {
                    aff = aff.concat(affiliationsOrder.indexOf(authorAff) + 1)
                })
                // if (!same) {
                    complete = complete + `\\textsuperscript{${aff.sort().join(',')}`
                    close = true
                // }
            }
            if (author.emails.length > 0 && authors.length > 1) {
                complete = complete + (close ? `,}\\Star` : `\\Star`)
            }
            else {
                complete = complete + (close ? '}' : '')
            }
            if (author.hasOrcid) {
                complete = complete + `\\orcid{${author.orcid.trim()}}`
            }
            all = all.concat(complete)
        })
        return all.join(', ')
    }

    // const allSame = () => {
    //     var affs = authors[0].affiliations
    //     var same = true
    //     authors.forEach((author) => {
    //         if (JSON.stringify(affs) !== JSON.stringify(author.affiliations)) {
    //             same = same & false
    //         }
    //     })
    //     return same
    // }

    const affiliationsLaTeX = (affiliationsOrder) => {
        var affs = []
        if (affiliationsOrder.length <= 1) {
            affiliationsOrder.forEach((ID, index) => {
                affs = affs.concat(`\\textit{${affiliations[ID].trim()}}`)
            })
            return affs.join('\\\\\n')
        }
        else {
            affiliationsOrder.forEach((ID, index) => {
                affs = affs.concat(`\\textsuperscript{${index + 1}}\\textit{${affiliations[ID].trim()}}`)
            })
            return affs.join('\\\\\n')
        }
    }

    const correspondingLaTeX = () => {
        var emails = []
        authors.forEach((author) => {
            if (author.emails.length > 0) {
                emails = emails.concat({name: `${author.firstName} ${author.lastName}`, emails: author.emails})
            }
        })
        if (emails.length === 0) {
            return ''
        }
        else if (emails.length === 1) {
            var emailsLaTeX = []
            emails[0].emails.forEach((email) => {
                emailsLaTeX = emailsLaTeX.concat(`\\email{${email.trim()}}`)
            })
            if (authors.length === 1) {
                return `\\textbf{Corresponding author:} ${emailsLaTeX.join(', ')}`
            }
            else {
                return `\\Star\\textbf{Corresponding author:} ${emailsLaTeX.join(', ')}`
            }
        }
        else {
            var all = ''
            emails.forEach((emailGroup, index) => {
                if (index !== 0) {
                    all = all + '\\\\'
                }
                var emailsLaTeX = []
                emailGroup.emails.forEach((email) => {
                    emailsLaTeX = emailsLaTeX.concat(`\\email{${email.trim()}}`)
                })
                all = all + `${emailGroup.name}: ${emailsLaTeX.join(', ')}`
            })
            return `\\Star\\textbf{Corresponding authors:}
\\begin{minipage}[t]{0.7\\linewidth}
    ${all}
\\end{minipage}
`
        }
    }

    const headerAuthors = () => {
        if (authors.length === 1) {
            return authors[0].lastName.trim()
        }
        else if (authors.length === 2) {
            return `${authors[0].lastName.trim()} \\& ${authors[1].lastName.trim()}`
        }
        else {
            return `${authors[0].lastName.trim()} et al.`
        }
    }

    const handleGenerateLatex = () => {
        const affOrder = getAffiliationsOrder()
        // const same = allSame()
        const isChicago = journalsInfo[journal].referenceStyle.includes('Chicago')
        const isJRS = journalsInfo[journal].referenceStyle.includes('JRS')
        const headerBib = isChicago ? '\n\n\\addbibresource{sample.bib}' : ''
        const bodyBib = isChicago ? '\\printbibliography' : '\\bibliography{sample.bib}'
        setStyle('OICC' + journalsInfo[journal].referenceStyle)
        const jrs = !isJRS ? '' : `\\FirstPageNumber{1}
\\LastPageNumber{\\pageref{LastPage}}
% \\LastPageNumber{50}

\\HeaderTitle{${headerTitle.trim()}}
`
        const chicagoGuide = !isChicago ? '' : `


%------------------Citation------------------%
% (Dorranian, 2023)   -->   \\autocite{label} %
% Dorranian (2023)    -->   \\textcite{label} %
% Dorranian's (2023)  -->   \\posscite{label} %
%--------------------------------------------%
`
        const latexTemplate = `\\documentclass{OICC${journalsInfo[journal].referenceStyle}}${headerBib}

\\Volume{${volume.trim()}}
\\Issue{${issue.trim()}}
\\ArticleNumber{${articleNumber.trim()}}
\\JournalName{${journalsInfo[journal].title.trim()}}
\\Info{\\url{https://doi.org/${doi.trim()}}}

\\PaperTitle{${title.trim()}}

\\Authors{${authorsLaTeX(affOrder)}}
\\Affiliations{${affiliationsLaTeX(affOrder)}}
\\Corresponding{${correspondingLaTeX()}}

\\ArticleType{${articleType !== 'Other' ? articleType : articleTypeOther.trim()}}
\\Received{${receivedDay} ${monthNames[receivedMonth]} ${receivedYear}}
\\Revised{${revisedDay} ${monthNames[revisedMonth]} ${revisedYear}}
\\Accepted{${acceptedDay} ${monthNames[acceptedMonth]} ${acceptedYear}}
\\Published{${publishedDay} ${monthNames[publishedMonth]} ${publishedYear}}
\\CopyrightYear{${publishedYear}}

\\Abstract{}
\\Keywords{${tagData.join('; ')}}

${jrs}\\HeaderAuthors{${headerAuthors()}}
\\HeaderJournal{${journalsInfo[journal].abbreviation} \\textbf{${volume.trim()}}(${publishedYear})}
\\FooterISSN{${journalsInfo[journal].ISSN}}
\\SetData${chicagoGuide}

\\begin{document}
\\maketitle
\\thispagestyle{empty}
% Replace this line with article content
\\EndNotes{
    \\textbf{Authors Contributions}\\\\
    All authors have contributed equally to prepare the paper.\\\\
    \\\\
    \\textbf{Availability of Data and Materials}\\\\
    The data that support the findings of this study are available from the corresponding author upon reasonable request.\\\\
    \\\\
    \\textbf{Conflict of Interests}\\\\
    The authors declare that they have no known competing financial interests or personal relationships that could have appeared to influence the work reported in this paper.\\\\
    \\\\
    \\textbf{Open Access}\\\\
    This article is licensed under a Creative Commons Attribution 4.0 International License, which permits use, sharing, adaptation, distribution and reproduction in any medium or format, as long as you give appropriate credit to the original author(s) and the source, provide a link to the Creative Commons license, and indicate if changes were made. The images or other third party material in this article are included in the article's Creative Commons license, unless indicated otherwise in a credit line to the material. If material is not included in the article's Creative Commons license and your intended use is not permitted by statutory regulation or exceeds the permitted use, you will need to obtain permission directly from the OICC Press publisher. To view a copy of this license, visit \\url{https://creativecommons.org/licenses/by/4.0}.
}
${bodyBib}
\\end{document}`;
        setLatexCode(latexTemplate);
    };

    const handleCopyLatex = () => {
        navigator.clipboard.writeText(latexCode);
    };
    
    const handleSetTagData = (data) => {
        setTagData(data);
    };

    useEffect(() => {
        Prism.highlightAll();
    }, [latexCode]);

    // const latexCodeLines = latexCode.split('\n');
    // const latexCodeWithLineNumbers = latexCodeLines.map((line, index) => {
    //     return `${index + 1} ${line}`;
    // }).join('\n');

    // const latexCodeWithLineNumbers = latexCodeLines.map((line, index) => {
    //     return `${index + 1} ${line}`;
    // }).join('\n');

    return (
        <div className="oicc-app">
        <div className="oicc-container">
            <div className="oicc-header">
                <h1 className="oicc-header-title">OICC Press <span class="l-let">L<span class="a-let">a</span>T<span class="e-let">e</span>X</span> Generator</h1>
            </div>
            <div className="oicc-form-container">
                <form>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Journal:</span>
                        <select value={journal} onChange={(e) => setJournal(e.target.value)} className="oicc-form-field-input">
                            {Object.keys(journalsInfo).map((journal, index) => (
                                <option key={index} value={journal}>{journalsInfo[journal].title}</option>
                            ))}
                        </select>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Article Type:</span>
                        <select value={articleType} onChange={(e) => setArticleType(e.target.value)} className="oicc-form-field-input">
                            <option value='Original Research'>Original Research</option>
                            <option value='Review Paper'>Review Paper</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>
                    {articleType === 'Other' &&
                        <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Article Type:</span>
                        <input type="text" value={articleTypeOther} onChange={(e) => setArticleTypeOther(e.target.value)} className="oicc-form-field-input"/>
                    </div>
                    }
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Volume:</span>
                        <input type="text" value={volume} onChange={(e) => setVolume(e.target.value)} className="oicc-form-field-input"/>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Issue:</span>
                        <input type="text" value={issue} onChange={(e) => setIssue(e.target.value)} className="oicc-form-field-input"/>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Article Number:</span>
                        <input type="text" value={articleNumber} onChange={(e) => setArticleNumber(e.target.value)} className="oicc-form-field-input"/>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">DOI:</span>
                        <input type="text" value={doi} placeholder="DOI number (starts with 10)" onChange={(e) => setDoi(e.target.value)} className="oicc-form-field-input oicc-wide-input"/>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Title:</span>
                        <textarea rows="2" value={title} onChange={(e) => setTitle(e.target.value)} className="oicc-form-field-input oicc-wide-input"/>
                    </div>
                    {journal === 'jrs' && <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Header Title:</span>
                        <input type="text" value={headerTitle} onChange={(e) => setHeaderTitle(e.target.value)} className="oicc-form-field-input oicc-wide-input" placeholder='A short version of title for page headers'/>
                    </div>}
                    {affiliations.map((affiliation, index) => (
                        <div className="oicc-form-field" key={index}>
                            <span className="oicc-form-field-label">Affiliation #{index + 1}:</span>
                            <textarea rows="2" value={affiliations[index]} onChange={(e) => updateAffiliation(index, e.target.value)} className="oicc-form-field-input oicc-wide-input"/>
                            {affiliations.length > 1 && <button type="button" onClick={() => removeAffiliation(index)} className="oicc-generate-button oicc-remove-button">×</button>}
                        </div>
                    ))}
                    <button type="button" onClick={() => setAffiliations([...affiliations, ''])} className="oicc-generate-button">Add Affiliation</button>
                    {authors.map((author, index) => (
                        <div className="oicc-form-field oicc-author-container" key={index}>
                            <span className="oicc-form-field-label">Author #{index + 1}:</span>
                            <div className='oicc-author-inputs'>
                                <div className='oicc-author-names'>
                                    <div>
                                        <span className="oicc-form-field-label">First Name:</span>
                                        <input type="text" value={author.firstName} onChange={(e) => updateAuthor(index, 'firstName', e.target.value)} className="oicc-form-field-input"/>
                                    </div>
                                    <div>
                                        <span className="oicc-form-field-label">Last Name:</span>
                                        <input type="text" value={author.lastName} onChange={(e) => updateAuthor(index, 'lastName', e.target.value)} className="oicc-form-field-input"/>
                                    </div>
                                </div>
                                <div className='oicc-author-names'>
                                    <div>
                                        <span className="oicc-form-field-label">Has ORCID:</span>
                                        <input type="checkbox" checked={author.hasOrcid} onChange={(e) => updateAuthor(index, 'hasOrcid', e.target.checked)}/>
                                    </div>
                                    {author.hasOrcid &&
                                        <div>
                                            <span className="oicc-form-field-label">ORCID:</span>
                                            <input type="text" value={author.orcid} onChange={(e) => updateAuthor(index, 'orcid', e.target.value)} className="oicc-form-field-input oicc-orcid-input" placeholder='Format: 1234-5678-9ABCD-EFGH'/>
                                        </div>
                                    }
                                </div>
                                {author.emails.length > 0 && 
                                    <>
                                        {author.emails.map((email, id) => (
                                            <div key={id} className='oicc-emails'>
                                                <span className="oicc-form-field-label">Email{author.emails.length > 1 && ` #${id + 1}`}:</span>
                                                <input type="text" value={email} onChange={(e) => updateAuthorEmail(index, id, e.target.value)} className="oicc-form-field-input oicc-email-input"/>
                                                <button type="button" onClick={() => removeAuthorEmail(index, id)} className="oicc-generate-button oicc-remove-button">×</button>
                                            </div>
                                        ))}
                                    </>
                                }
                                <button type="button" onClick={() => addAuthorEmail(index)} className="oicc-generate-button">Add Email</button>
                                <div className='oicc-emails'>
                                    <span className="oicc-form-field-label">Affiliation(s):</span>
                                    <div className='oicc-affs-group'>
                                        <select multiple value={author.affiliations} onChange={(e) => handleAuthorAffiliations(e, index)} className="oicc-form-field-input">
                                            {affiliations.map((affiliation, id) => (
                                                <option key={id} value={id}>Affiliation #{id + 1}</option>
                                            ))}
                                        </select>
                                        <span className='oicc-footnote'>Hold ctrl to select multiple options.</span>
                                    </div>
                                </div>
                            </div>
                            {authors.length > 1 && <button type="button" onClick={() => removeAuthor(index)} className="oicc-generate-button oicc-remove-button">×</button>}
                        </div>
                    ))}
                    <button type="button" onClick={addAuthor} className="oicc-generate-button">Add Author</button>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Received Date:</span>
                        <select className="oicc-form-field-input" value={receivedDay} onChange={(e) => setReceivedDay(e.target.value)}>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                            <option key={day} value={day}>
                            {day}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={receivedMonth} onChange={(e) => setReceivedMonth(e.target.value)}>
                        {Array.from({ length: 12 }, (_, i) => i).map((month) => (
                            <option key={month} value={month}>
                            {`${month + 1}. ${monthNames[month]}`}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={receivedYear} onChange={(e) => setReceivedYear(e.target.value)}>
                        {Array.from({ length: 3 }, (_, i) => 2025 - i).map((year) => (
                            <option key={year} value={year}>
                            {year}
                            </option>
                        ))}
                        </select>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Revised Date:</span>
                        <select className="oicc-form-field-input" value={revisedDay} onChange={(e) => setRevisedDay(e.target.value)}>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                            <option key={day} value={day}>
                            {day}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={revisedMonth} onChange={(e) => setRevisedMonth(e.target.value)}>
                        {Array.from({ length: 12 }, (_, i) => i).map((month) => (
                            <option key={month} value={month}>
                            {`${month + 1}. ${monthNames[month]}`}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={revisedYear} onChange={(e) => setRevisedYear(e.target.value)}>
                        {Array.from({ length: 3 }, (_, i) => 2025 - i).map((year) => (
                            <option key={year} value={year}>
                            {year}
                            </option>
                        ))}
                        </select>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Accepted Date:</span>
                        <select className="oicc-form-field-input" value={acceptedDay} onChange={(e) => setAcceptedDay(e.target.value)}>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                            <option key={day} value={day}>
                            {day}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={acceptedMonth} onChange={(e) => setAcceptedMonth(e.target.value)}>
                        {Array.from({ length: 12 }, (_, i) => i).map((month) => (
                            <option key={month} value={month}>
                            {`${month + 1}. ${monthNames[month]}`}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={acceptedYear} onChange={(e) => setAcceptedYear(e.target.value)}>
                        {Array.from({ length: 3 }, (_, i) => 2025 - i).map((year) => (
                            <option key={year} value={year}>
                            {year}
                            </option>
                        ))}
                        </select>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Published Date:</span>
                        <select className="oicc-form-field-input" value={publishedDay} onChange={(e) => setPublishedDay(e.target.value)}>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                            <option key={day} value={day}>
                            {day}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={publishedMonth} onChange={(e) => setPublishedMonth(e.target.value)}>
                        {Array.from({ length: 12 }, (_, i) => i).map((month) => (
                            <option key={month} value={month}>
                            {`${month + 1}. ${monthNames[month]}`}
                            </option>
                        ))}
                        </select>
                        <select className="oicc-form-field-input" value={publishedYear} onChange={(e) => setPublishedYear(e.target.value)}>
                        {Array.from({ length: 3 }, (_, i) => 2025 - i).map((year) => (
                            <option key={year} value={year}>
                            {year}
                            </option>
                        ))}
                        </select>
                    </div>
                    <div className="oicc-form-field">
                        <span className="oicc-form-field-label">Keywords:</span>
                        <InputTag tagData={tagData} setTagData={handleSetTagData}/>
                    </div>
                </form>
                <div className='oicc-code-buttons'>
                    <button type="button" onClick={handleGenerateLatex} className="oicc-generate-button">Generate LaTeX</button>
                    <button type="button" onClick={resetAll} className="oicc-generate-button">Reset</button>
                </div>
            </div>
            {latexCode && (
                <div className="oicc-latex-code-container">
                    <h2 className="oicc-latex-code-title">Generated LaTeX Code:</h2>
                    <pre className="oicc-latex-code-block">
                        {/* <div className="oicc-line-numbers"> */}
                            {/* {latexCode.split('\n').map((line, index) => (
                                <span key={index}>{index + 1}</span>
                            ))} */}
                        {/* </div> */}
                        <code id="oicc-latex-code" className="oicc-latex-code language-latex">{latexCode}</code>
                    </pre>
                    <div className='oicc-code-buttons'>
                        <button type="button" onClick={handleCopyLatex} className="oicc-generate-button">Copy to Clipboard</button>
                        <button type="button" onClick={() => {
                            const link = document.createElement('a');
                            link.href = `data:text/plain;charset=utf-8,${encodeURIComponent(latexCode)}`;
                            link.download = `${journalsInfo[journal].abbreviation+articleNumber}.tex`;
                            link.click();
                        }} className="oicc-generate-button">Download as .tex file</button>
                        <button type="button" onClick={() => {
                            const link = document.createElement('a');
                            link.href = 'https://scienceproof.xyz/uploads/v1.9/' + style + '.cls';
                            link.target = '_blank';
                            link.rel = 'noopener noreferrer';
                            link.download = style + '.cls';
                            link.click();
                        }} className="oicc-generate-button">{style}.cls</button>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
}

export default OICCLaTeX