import React from "react";
// import React, { Component } from "react";
// import Dashboard from "./Dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route, Link, Redirect } from "react-router-dom";
import "./HomePage.css"
// import AddNew from "./AddNew";
import ViewArticle from "./ViewArticle";
import Login from "./Login";
import PrivateRoute from "../utilities/PrivateRoute2";
import AuthContext, { GetContextData } from "../context/AuthContext2";
import Journals from "./Journals";
import Manage from "./Manage";
import ResponseView from "./ResponseView";
import NewPassword from "./NewPassword";
import NotFound from "./NotFound";
import OICCLaTeX from "./OICCLaTeX";

const HomePage = () => {

    const contextData = GetContextData();

    return (
        <Router>
            <AuthContext.Provider value={contextData}>
                <Routes>
                    <Route path="/" element={<PrivateRoute><Journals /></PrivateRoute>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/oicc/latex-generator" element={<OICCLaTeX />} />
                    <Route path="/password/:token" element={<NewPassword />} />
                    <Route path="/p/:token" element={<ResponseView />} />
                    <Route path="/:jID/dashboard" element={<PrivateRoute><Manage /></PrivateRoute>} />
                    <Route path="/:jID/:aID" element={<PrivateRoute><ViewArticle /></PrivateRoute>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AuthContext.Provider>
        </Router>
    );
}

export default HomePage