import React from "react";
import './InputTag.css'

const InputTag = ({ tagData, setTagData }) => {
    // const [tagData, setTagData] = React.useState(tags);
  
    const removeTagData = indexToRemove => {
        setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
    };
    
    const addTagData = event => {
        if (event.target.value !== '') {
            const newValue = event.target.value.trim();
            if (!tagData.includes(newValue)) {
                setTagData([...tagData, newValue]);
                event.target.value = '';
            }
        }
    };
  
    return (
        <div className="tag-input oicc-form-field-input">
            <ul className="tags">
                {tagData.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        <span className="tag-close-icon" onClick={() => removeTagData(index)}>×</span>
                    </li>
                ))}
            </ul>
            <input type="text" onKeyUp={event => (event.key === 'Enter' ? addTagData(event) : null)} placeholder="Press enter to add a keyword"/>
        </div>
    );
  };

export default InputTag;